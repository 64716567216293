import { Modal } from "antd";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import FormikInput from "~/components/Input/FormikInput";
import { ResourcesRoutes } from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";
import FormikAntdTreeSelect from "../../CreateTask/FormikAntdTreeSelect";

export default function ResourceCreateOrUpdateModal(props) {
  const { open, onCancel, initialEditingValues = null, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const { setToast } = useContext(ToastContext);
  const [initialValues, setInitialValues] = useState(initialEditingValues);
  const [fieldSetsOptions, setFieldSetsOptions] = useState();
  const { companyId, projectInfos } = useSelector(userSelector);

  const formValidation = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Campo obrigatório!";
    }
    if (!values.unit) {
      errors.unit = "Campo obrigatório!";
    }
    if (!values.subproject_id) {
      errors.subproject_id = "Campo obrigatório!";
    }

    return errors;
  };

  const transformedOptions = projectInfos.map((project) => ({
    name: project.name,
    value: `project_${project.id}`, //caso o ID do project seja igual ao do subproject
    children: project.subProjects.map((subProject) => ({
      name: subProject.name,
      value: subProject.id,
    })),
  }));

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data: resourcesFieldSet } =
          await ResourcesRoutes.getResourcesFieldSetByCompany(companyId);

        setFieldSetsOptions(
          resourcesFieldSet?.data?.map((item) => ({
            name: item.name,
            value: item.id,
          })),
        );
      } catch (error) {
        console.error(error);
        setToast({
          type: "error",
          title: "Erro",
          message: "Um erro ocorreu durante a listagem de opções.",
          show: true,
          autohide: true,
        });
      }
    };

    getData();
    setLoading(false);
  }, [companyId]);

  useEffect(() => {
    if (initialEditingValues) {
      setInitialValues(initialEditingValues);
    }
  }, [initialEditingValues]);

  const defaultValues = {
    name: "",
    description: "",
    unit: "",
    minimum_stock: 0,
    subproject_id: null,
    resource_field_set_id: null,
  };

  return (
    <Modal
      title={
        initialValues ? (
          <h5
            style={{ color: "var( --blue-color)" }}
          >{`Atualizar ${initialValues.name}`}</h5>
        ) : (
          <h5
            style={{ color: "var( --blue-color)" }}
          >{`Cadastrar Novo Recurso`}</h5>
        )
      }
      open={open}
      style={{
        borderRadius: "5px",
        overflow: "hidden",
        padding: 0,
      }}
      bodyStyle={{
        borderRadius: "6px",
        padding: "20px",
      }}
      onCancel={onCancel}
      width="35%"
      destroyOnClose
      afterClose={() => setInitialValues(null)}
      footer={null}
    >
      <Formik
        initialValues={initialValues || defaultValues}
        enableReinitialize
        validate={formValidation}
        onSubmit={async (values, { setSubmitting, status }) => {
          try {
            const { data: newResource } = !initialValues
              ? await ResourcesRoutes.postResourceRegister(companyId, values)
              : await ResourcesRoutes.putResourceRegister(
                  companyId,
                  values,
                  initialValues.id,
                );

            const formattedResource = {
              id: newResource.data.id,
              name: newResource.data.name,
              description: newResource.data.description,
              unit: newResource.data.unit,
              subproject_id: newResource.data.subproject_id,
              resource_field_set_id: newResource.data.resource_field_set_id,
              minimum_stock: Number(newResource.data.minimum_stock),
              amount: !initialValues ? 0 : initialValues.amount,
              total_input: 0,
              total_output: 0,
            };

            setToast({
              type: "success",
              title: "Sucesso",
              message: "Recurso cadastrado com sucesso!",
              show: true,
              autohide: true,
            });

            onSuccess(formattedResource);
          } catch (err) {
            console.log(err);

            setToast({
              type: "error",
              title: "Erro",
              message: "Um erro inesperado ocorreu!",
              show: true,
              autohide: true,
            });
          } finally {
            setSubmitting(false);
            onCancel();
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          handleChange,
          touched,
          onSuccess,
          values,
        }) => (
          <Form>
            <div className="d-flex flex-column" style={{ gap: 24 }}>
              <FormikInput
                placeholder="Nome do Recurso"
                name="name"
                error={errors.name}
              />
              <FormikInput
                placeholder="Descrição"
                name="description"
                error={errors.description}
                inputType="textArea"
              />
              <FormikInput
                placeholder="Unidade"
                name="unit"
                error={errors.unit}
              />
              <FormikInput
                placeholder="Estoque mínimo"
                name="minimum_stock"
                error={errors.minimum_stock}
              />
              <div style={{ display: "flex", gap: "18px" }}>
                <FormikAntdTreeSelect
                  name="subproject_id"
                  value={values.subproject_id}
                  errors={errors.subproject_id}
                  touched={touched.subproject_id}
                  placeholder="Subprojeto"
                  options={transformedOptions}
                  inputPlaceholder=""
                  inputBackground="transparent"
                />
                <FormikAntdTreeSelect
                  name="resource_field_set_id"
                  value={values.resource_field_set_id}
                  errors={errors.resource_field_set_id}
                  touched={touched.resource_field_set_id}
                  placeholder="Conjunto de Campos Adicionais"
                  options={fieldSetsOptions}
                  inputPlaceholder=""
                  inputBackground="transparent"
                />
              </div>

              <div className="d-flex justify-content-center">
                <button
                  className="btn-default w-25"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : initialValues ? (
                    "Atualizar"
                  ) : (
                    "Adicionar"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
