import {
  ArrowRightOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditTwoTone,
  ExclamationCircleOutlined,
  PlusCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { Table, Input, Popover, Modal, Carousel, Tabs } from "antd";
import { useState, useMemo, useCallback, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "~/components/Button/Button";
import { ResourcesRoutes } from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";
import ResourceCreateOrUpdateModal from "./components/ResourceCreateOrUpdateModal";
import ResourceInputModal from "./components/ResourceInputModal";
import checkPermission from "~/helpers/checkPermission";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import "./ResourcesList.css";
import ResourceFieldsCreateOrUpdateModal from "./components/ResourceFieldsCreateOrUpdateModal";
const { TabPane } = Tabs;

export default function ResourcesList() {
  const [loading, setLoading] = useState(false);
  const [resourceCreateModalOpen, setResourceCreateModalOpen] = useState(false);
  const [resourceFieldCreateModalOpen, setResourceFieldCreateModalOpen] =
    useState(false);
  const [resourceInputModalOpen, setResourceInputModalOpen] = useState(false);
  const [reload, setReload] = useState("");
  const [resourceInputInfo, setResourceInputInfo] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [resources, setResources] = useState([]);
  const [resourcesFieldSet, setResourcesFieldSet] = useState([]);
  const [initialEditingValues, setInitialEditingValues] = useState(null);
  const [initialFieldsEditingValues, setInitialFieldsEditingValues] =
    useState(null);
  const { push } = useHistory();
  const { companyId } = useSelector(userSelector);
  const { setToast } = useContext(ToastContext);

  const formattedValue = (value) =>
    new Intl.NumberFormat("pt-BR").format(value);

  const handleAddOrUpdateResource = (updatedResource) => {
    setResources((prevResources) => {
      const updatedResources = prevResources.map((resource) =>
        resource.id === updatedResource.id ? updatedResource : resource,
      );
      if (
        !updatedResources.some((resource) => resource.id === updatedResource.id)
      ) {
        updatedResources.push(updatedResource);
      }
      setReload(Date.now());
      return updatedResources;
    });
  };

  const handleAddOrUpdateResourceFieldSet = (updatedResourceFieldSet) => {
    setResourcesFieldSet((prevResourcesFieldSet) => {
      const updatedData = prevResourcesFieldSet.map((resource) =>
        resource.id === updatedResourceFieldSet.id
          ? updatedResourceFieldSet
          : resource,
      );
      if (
        !updatedData.some(
          (resource) => resource.id === updatedResourceFieldSet.id,
        )
      ) {
        updatedData.push(updatedResourceFieldSet);
      }
      setReload(Date.now());
      return updatedData;
    });
  };

  const handleAddResourceInput = (updatedResourceInput) => {
    setResources((prevResources) => {
      return prevResources.map((resource) => {
        if (resource.id === updatedResourceInput.id) {
          return {
            ...resource,
            amount:
              Number(resource.amount) + Number(updatedResourceInput.quantity),
            total_input:
              Number(resource.total_input) +
              Number(updatedResourceInput.quantity),
          };
        }
        return resource;
      });
    });
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data: resourcesData } = await ResourcesRoutes.getByCompany(
          companyId,
        );
        const { data: resourcesFieldSet } =
          await ResourcesRoutes.getResourcesFieldSetByCompany(companyId);

        //   const sortedResources = resourcesData.data.sort(
        //     (a, b) => new Date(a.created_at) - new Date(b.created_at),
        //   );

        setResources(resourcesData.data);
        setResourcesFieldSet(resourcesFieldSet.data);
      } catch (error) {
        console.error(error);
        setToast({
          type: "error",
          title: "Erro",
          message: "Um erro ocorreu durante a listagem dos recursos.",
          show: true,
          autohide: true,
        });
      }
    };

    getData();
    setLoading(false);
  }, [companyId, reload]);

  const filteredResource = useMemo(() => {
    const lowerSearchValue = searchValue.toLowerCase();
    return resources.filter(
      ({ name }) => name && name.toLowerCase().includes(lowerSearchValue),
    );
  }, [searchValue, resources]);

  const handleSearchChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  async function deleteResource(id) {
    await ResourcesRoutes.deleteResourceRegisterById(companyId, id);
    setResources((prev) => prev.filter((item) => item.id !== id));
    setToast({
      type: "success",
      title: "Sucesso!",
      message: "Recurso deletado!",
      show: true,
      autohide: true,
    });
  }

  async function deleteFieldSet(id) {
    await ResourcesRoutes.deleteResourcesFieldSet(companyId, id);
    setResourcesFieldSet((prev) => prev.filter((item) => item.id !== id));
    setToast({
      type: "success",
      title: "Sucesso!",
      message: "Conjunto deletado!",
      show: true,
      autohide: true,
    });
  }

  const { confirm } = Modal;

  const showConfirmDeleteTaskModal = (columnId) => {
    confirm({
      icon: <ExclamationCircleOutlined style={{ fontSize: "3em" }} />,
      content: `Tem certeza que deseja excluir o Recurso?`,
      okText: "Excluir",
      okButtonProps: {
        style: {
          alignContent: "center",
          display: "inline-flex",
          alignItems: "center",
          alignSelf: "center",
        },
        danger: true,
        icon: <DeleteOutlined></DeleteOutlined>,
      },
      cancelText: "Cancelar",
      closable: true,
      onOk() {
        deleteResource(columnId);
      },
    });
  };

  const showConfirmDeleteFieldSetModal = (columnId) => {
    confirm({
      icon: <ExclamationCircleOutlined style={{ fontSize: "3em" }} />,
      content: `Tem certeza que deseja excluir o Recurso?`,
      okText: "Excluir",
      okButtonProps: {
        style: {
          alignContent: "center",
          display: "inline-flex",
          alignItems: "center",
          alignSelf: "center",
        },
        danger: true,
        icon: <DeleteOutlined></DeleteOutlined>,
      },
      cancelText: "Cancelar",
      closable: true,
      onOk() {
        deleteFieldSet(columnId);
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        key: "name",
        title: "Nome",
        dataIndex: "name",
        width: "20%",
      },
      {
        key: "description",
        title: "Descrição",
        dataIndex: "description",
        width: "30%",
      },
      {
        key: "input",
        title: "Entradas",
        dataIndex: "input",
        width: "10%",
        render: (_, record) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{
                color: "var(--blue-color)",
              }}
            >
              {formattedValue(record.total_input)}
            </div>
          );
        },
      },
      {
        key: "output",
        title: "Saídas",
        dataIndex: "output",
        width: "10%",
        render: (_, record) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{
                color: "orange",
              }}
            >
              {formattedValue(record.total_output)}
            </div>
          );
        },
      },
      {
        key: "amount",
        title: "Saldo",
        dataIndex: "amount",
        width: "10%",
        render: (amount, record) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{
                color: amount < record.minimum_stock ? "red" : "inherit",
              }}
            >
              {formattedValue(amount)}
            </div>
          );
        },
      },
      {
        key: "unit",
        title: "Unidade",
        dataIndex: "unit",
        width: "10%",
        render: (unit) => {
          return (
            <div className="d-flex align-items-center">
              {unit.toUpperCase()}
            </div>
          );
        },
      },
      {
        key: "minimum",
        title: "Estoque mínimo",
        dataIndex: "minimum_stock",
        width: "10%",
        render: (minimum_stock) => {
          return (
            <div className="d-flex align-items-center">
              {formattedValue(minimum_stock)}
            </div>
          );
        },
      },
      {
        key: "add",
        title: "Ações",
        align: "center",
        //   width: "24%",
        render: (_, record) => {
          const isDisabled = !checkPermission("editar-recursos");

          const disabledStyle = {
            color: "lightgray",
            cursor: "not-allowed",
          };

          return (
            <div
              className="d-flex"
              style={{
                alignItems: "center",
                gap: 24,
                justifyContent: "center",
              }}
            >
              <Popover placement="top" content="Adicionar ao Total">
                <PlusCircleTwoTone
                  twoToneColor={isDisabled ? "lightgray" : "#52c41a"}
                  className="inventory__icons"
                  style={isDisabled ? disabledStyle : {}}
                  onClick={() => {
                    if (!isDisabled) {
                      setResourceInputInfo({
                        id: record.id,
                        name: record.name,
                        fieldSet: record.fieldSet,
                      });
                      setResourceInputModalOpen(true);
                    }
                  }}
                />
              </Popover>
              <Popover placement="top" content="Editar Recurso">
                <EditTwoTone
                  twoToneColor={isDisabled ? "lightgray" : ""}
                  className="inventory__icons"
                  style={isDisabled ? disabledStyle : {}}
                  onClick={() => {
                    if (!isDisabled) {
                      setInitialEditingValues({
                        id: record.id,
                        name: record.name,
                        description: record.description,
                        unit: record.unit,
                        minimum_stock: record.minimum_stock,
                        amount: record.amount,
                        subproject_id: record.subproject_id,
                        resource_field_set_id: record.resource_field_set_id,
                      });
                      setResourceCreateModalOpen(true);
                    }
                  }}
                />
              </Popover>
              <Popover placement="top" content="Remover Recurso">
                <DeleteTwoTone
                  twoToneColor={isDisabled ? "lightgray" : "red"}
                  className="inventory__icons"
                  style={isDisabled ? disabledStyle : {}}
                  onClick={() => {
                    if (!isDisabled) {
                      showConfirmDeleteTaskModal(record.id);
                    }
                  }}
                />
              </Popover>
            </div>
          );
        },
      },
    ],
    [],
  );

  const columnsFields = useMemo(
    () => [
      {
        key: "name",
        title: "Nome",
        dataIndex: "name",
        width: "70%",
      },
      {
        key: "fields_sum",
        title: "Total de Campos",
        dataIndex: "fields_sum",
        width: "15%",
        render: (_, record) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{
                color: "var(--blue-color)",
              }}
            >
              {record.fields?.length}
            </div>
          );
        },
      },
      {
        key: "add",
        title: "Ações",
        align: "center",
        //   width: "24%",
        render: (_, record) => {
          const isDisabled = !checkPermission("editar-recursos");

          const disabledStyle = {
            color: "lightgray",
            cursor: "not-allowed",
          };

          return (
            <div
              className="d-flex"
              style={{
                alignItems: "center",
                gap: 24,
                justifyContent: "center",
              }}
            >
              <Popover placement="top" content="Editar Campos">
                <EditTwoTone
                  twoToneColor={isDisabled ? "lightgray" : ""}
                  className="inventory__icons"
                  style={isDisabled ? disabledStyle : {}}
                  onClick={() => {
                    if (!isDisabled) {
                      setInitialFieldsEditingValues({
                        id: record.id,
                        name: record.name,
                        subproject_id: record.subproject_id,
                        fields: record.fields,
                      });
                      setResourceFieldCreateModalOpen(true);
                    }
                  }}
                />
              </Popover>
              <Popover placement="top" content="Remover Campos">
                <DeleteTwoTone
                  twoToneColor={isDisabled ? "lightgray" : "red"}
                  className="inventory__icons"
                  style={isDisabled ? disabledStyle : {}}
                  onClick={() => {
                    if (!isDisabled) {
                      showConfirmDeleteFieldSetModal(record.id);
                    }
                  }}
                />
              </Popover>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <Breadcrumb title="Recursos" routeToBack="/home/dashboard" />

      <ResourceCreateOrUpdateModal
        open={resourceCreateModalOpen}
        onCancel={() => {
          setResourceCreateModalOpen(false);
          setInitialEditingValues(null);
        }}
        onSuccess={handleAddOrUpdateResource}
        initialEditingValues={initialEditingValues}
      />
      <ResourceInputModal
        open={resourceInputModalOpen}
        onCancel={() => {
          setResourceInputModalOpen(false);
        }}
        onSuccess={handleAddResourceInput}
        resourceInfo={resourceInputInfo}
      />
      <ResourceFieldsCreateOrUpdateModal
        open={resourceFieldCreateModalOpen}
        onCancel={() => {
          setResourceFieldCreateModalOpen(false);
          setInitialFieldsEditingValues(null);
        }}
        onSuccess={handleAddOrUpdateResourceFieldSet}
        initialEditingValues={initialFieldsEditingValues}
      />

      <Tabs defaultActiveKey="1" animated style={{ marginTop: "24px" }}>
        <TabPane
          tab={<span style={{ fontSize: "1rem" }}>Lista de Recursos</span>}
          key="1"
          style={{ height: "100%", maxWidth: "100%" }}
        >
          <div>
            <div
              className="inventory__filters"
              style={{ marginBottom: "40px" }}
            >
              <Input
                value={searchValue}
                onChange={handleSearchChange}
                className="inventory__filters--search"
                size="large"
                placeholder="Pesquise pelo nome do Recurso"
                prefix={<SearchOutlined />}
              />

              <Button
                disabled={!checkPermission("editar-recursos")}
                size="large"
                withIcon
                onClick={() => setResourceCreateModalOpen(true)}
              >
                Novo Cadastro
              </Button>
            </div>
            {!loading && (
              <Table
                rowKey="id"
                columns={columns}
                dataSource={filteredResource}
              />
            )}
          </div>
        </TabPane>

        <TabPane
          tab={<span style={{ fontSize: "1rem" }}>Lista de Campos</span>}
          key="2"
        >
          <div>
            <div
              className="d-flex"
              style={{
                marginBottom: "40px",
                marginTop: "20px",
                justifyContent: "end",
              }}
            >
              <Button
                style={{
                  width: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "600",
                  borderRadius: "5px",
                }}
                disabled={!checkPermission("editar-recursos")}
                size="large"
                withIcon
                onClick={() => setResourceFieldCreateModalOpen(true)}
              >
                Novo Cadastro
              </Button>
            </div>
            {!loading && (
              <Table
                rowKey="id"
                columns={columnsFields}
                dataSource={resourcesFieldSet}
              />
            )}
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
