import { useField } from "formik";
import React, { useCallback } from "react";
import Input from "./Input";
import Select from "~/components/Select/Select";

const FormikInput = (props) => {
  const [{ onChange, value, name, options }, { error, touched }] =
    useField(props);

  const handleChange = (event) => {
    let { value } = event.target;
    if (props.typeInput === "money") {
      value = value.replace(/[^\d]/g, "");
    }
    onChange({ target: { name, value } });
  };

  const renderInputByType = useCallback(() => {
    switch (props.typeInput) {
      case "select":
        return (
          <Select
            {...props}
            placeholder="Serviço"
            errors={error}
            touched={touched}
            value={value}
            handleChange={handleChange}
            inputBackground="transparent"
            labelBackground="#fafafa"
            inputPlaceholder=""
            options={options}
            withNull={true}
          />
        );

      case "money":
        return (
          <Input
            {...props}
            name={name}
            value={value}
            handleChange={handleChange}
            errors={error}
            touched={touched}
            inputType={"money"}
          />
        );

      case "text":
      default:
        return (
          <Input
            {...props}
            name={name}
            value={value}
            handleChange={handleChange}
            errors={error}
            touched={touched}
          />
        );
    }
  }, [props, error, touched, value, name]);

  return renderInputByType();
};

export default FormikInput;
