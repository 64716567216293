import axios from "axios";
import { get, post, put, patch, remove } from "../Axios";

/**
 * TODO - Refatorar as rotas, pois está retornando todos os métodos em 1 import,
 * o ideal seria importar sob demanda ex { logout } from LoginRouts
 * */
export const LoginRoutes = {
  postSession: (params) => post("session", params),
  getUserInfo: () => get("user/info"),
  logout: () => post("logout"),
  refreshToken: () => post("/refresh"),
};

export const UserRoutes = {
  store: (user) => post("/user", user),
  updateUser: (id, params) => put(`/user/${id}`, params),
  associateCompany: (companyId, id, userProfileId, role = "") =>
    post("/company/association", {
      company_id: companyId,
      user_id: id,
      profile: "",
      user_profile_id: userProfileId,
      role,
    }),
  disassociateCompany: (companyId, id) =>
    post("/company/desassociation", {
      company_id: companyId,
      user_id: id,
    }),
  updateUserProfile: (companyId, id, body) =>
    put(`${companyId}/user/${id}/profile`, body),
  findUser: (id) => get(`/user/${id}`),
  getUsers: () => get("/user?disabled=false"),
  findUserByEmail: (email) => get(`user/${email}/by-email`),
  getCompanyUsers: (companyId) => get(`${companyId}/users`),
  getCompanyUsersShort: (companyId, withPhoto = false, subprojectId) =>
    get(
      `${companyId}/users/short?${withPhoto ? "withPhoto=true&" : ""}${
        subprojectId ? `subproject_id=${subprojectId}` : ""
      }`,
    ),
  getPermissions: (companyId, id = null) =>
    get(`/user/${companyId}/permission/${id || ""}`),
  associatePermissions: (companyId, id, permissions) =>
    post(`/user/${companyId}/permission/${id}`, {
      clearCurrent: true,
      permissions,
    }),
  getProjects: (companyId, id) => get(`/user/${companyId}/project/${id}`),
  associateProject: (companyId, id, projects) =>
    post(`/user/${companyId}/project/${id}`, { clearCurrent: true, projects }),
  associateSubproject: (companyId, id, subprojects) =>
    post(`/user/${companyId}/subproject/${id}`, {
      clearCurrent: true,
      subprojects,
    }),
  associateSubprojectWithoutClear: (companyId, id, subprojects) =>
    post(`/user/${companyId}/subproject/${id}`, {
      clearCurrent: false,
      subprojects,
    }),
  postForgotPasswordToken: (email) => post(`/user/forgot`, email),
  postNewPassword: (data) => post(`/user/new-password`, data),
};

export const TaskRoutes = {
  urlReports: (companyId, params) => {
    let filters = "";

    Object.entries(params).forEach(([key, value]) => {
      filters += !filters.length ? `${key}=${value}` : `&${key}=${value}`;
    });

    const url = `${companyId}/task/report${
      filters.length ? "?" + filters : ""
    }`;
    return url;
  },

  updateTask: (params, taskId, subprojectId) =>
    put(subprojectId + "/task/" + taskId, params),
  deleteTask: (ata_id, ata_subject_id, id) =>
    remove(`ata/${ata_id}/subjects/${ata_subject_id}/tasks/${id}`),
  removeTask: (taskId) => remove(`/task/${taskId}`),
  getTask: (subprojectId, id) => get(`${subprojectId}/task/${id}`),
  getTasks: (params) => get(params + "/task"),
  list: (subproject_id, user_id) =>
    get(`${subproject_id}/task/new-list${user_id ? "/" + user_id : ""}`),
  getReport: async (companyId, params) => {
    return await get(TaskRoutes.urlReports(companyId, params));
  },
  getTimeLineURL: async (companyId, params) => {
    const timeline = await axios.post(
      `https://chatpilar.herokuapp.com/` +
        TaskRoutes.urlReports(companyId, params),
    );
    return timeline.config.url;
  },
  getDaily: () => get("task/daily"),
  getPriorities: () => get("task/priorities"),
  getColumns: (companyId) => get(companyId + "/statuses"),
  postComment: (taskId, body) => post(`/${taskId}/comment`, body),
  uploadAttachment: (subprojectId, taskId, attachment, customHeader) =>
    post(
      `/${subprojectId}/task/${taskId}/attachment`,
      attachment,
      customHeader,
    ),
  postNotification: (body) => post("notification/task", body),
  getIndicators: (companyId, body) =>
    post(`/${companyId}/task/indicators`, body),
  toFile: (taskId) => post(`/${taskId}/to-file`),
  patchSubProjecId: (taskId, subprojectId) =>
    patch(`/new-subproject-id/${subprojectId}/task/${taskId}`),
};

export const permissionRoutes = {
  list: () => get("/permission"),
  userProfiles: (companyId) => get(`user-profile?company_id=${companyId}`),
};

export const formRoutes = {
  store: (body) => post("/task/form", body),
  update: (id, body) => put(`/task/form/${id}`, body),
  list: () => get("/task/form"),
  listByCompanyId: (companyId) => get(`/task/form/company_id/${companyId}`),
  find: (id) => get(`/task/form/${id}`),
  types: () => get("/task/form/types"),
  storeField: (formId, body) => post(`/task/form/${formId}/field`, body),
  updateField: (formId, id, body) =>
    put(`/task/form/${formId}/field/${id}`, body),
  deleteField: (formId, id) => remove(`/task/form/${formId}/field/${id}`),
};

export const ListOptionsRoutes = {
  find: (id) => get(`/list-option/form/${id}`),
  findByTaskId: (id) => get(`/list-option/form/task/${id}`),
  store: (id, body) => post(`/list-option/form/${id}`, body),
};

export const TaskFieldRoutes = {
  store: (taskId, body) => post(`/${taskId}/fields`, body),
  update: (taskId, id, body) => put(`/${taskId}/fields/${id}`, body),
  updateMany: (taskId, body) => put(`/${taskId}/fields/many`, body),
  delete: (taskId, id) => remove(`/${taskId}/fields/${id}`),
  list: (taskId) => get(`/${taskId}/fields`),
  find: (taskId, id) => get(`/${taskId}/fields/${id}`),
  findByLabel: (label) => get(`/fields/label/${label}`),
};

export const taskFieldAttachmentRoutes = {
  post: (subprojectId, taskId, taskFieldId, body) =>
    post(
      `/${subprojectId}/task/${taskId}/field/${taskFieldId}/attachment`,
      body,
    ),
  update: (subprojectId, taskId, taskFieldId, id, body) =>
    put(
      `/${subprojectId}/task/${taskId}/field/${taskFieldId}/attachment/${id}`,
      body,
    ),
  delete: (subprojectId, taskId, taskFieldId, id, body) =>
    remove(
      `/${subprojectId}/task/${taskId}/field/${taskFieldId}/attachment/${id}`,
      body,
    ),
};

export const ProjectRoutes = {
  getProjects: (companyId) => get(`${companyId}/project`),
  getProjectById: (companyId, projectId) =>
    get(`${companyId}/project/${projectId}`),
  save: (companyId, project) => post(`${companyId}/project`, project),
  update: (companyId, project) => {
    return put(`${companyId}/project/${project.id}`, project);
  },
  deleteById: (companyId, projectId) =>
    remove(`${companyId}/project/${projectId}`),
};

export const SubprojectRoutes = {
  findAll: (projectId) => get(`${projectId}/subproject`),
  findById: (projectId, subprojectId) =>
    get(`${projectId}/subproject/${subprojectId}`),
  save: (projectId, subproject) => post(`${projectId}/subproject`, subproject),
  update: (projectId, subproject) =>
    put(`${projectId}/subproject/${subproject.id}`, subproject),
  deleteById: (projectId, subprojectId) =>
    remove(`${projectId}/subproject/${subprojectId}`),
};

export const ServiceRoutes = {
  getServices: (companyId, subprojectId) =>
    get(
      `${companyId}/service?${
        subprojectId ? `sub_project_id=${subprojectId}&` : ""
      }`,
    ),
  getServiceById: (companyId, serviceId) =>
    get(`${companyId}/service/${serviceId}`),
  save: (companyId, service) => post(`${companyId}/service`, service),
  update: (companyId, service) =>
    put(`${companyId}/service/${service.id}`, service),
  deleteById: (companyId, service) =>
    remove(`${companyId}/service/${service.id}`),
};
export const StatusesRoutes = {
  getStatuses: (companyId, subprojectId) =>
    get(
      `${companyId}/statuses?${
        subprojectId ? `sub_project_id=${subprojectId}&` : ""
      }`,
    ),
  getStatus: (companyId, subprojectId) =>
    get(`${companyId}/statuses/${subprojectId}`),
  getStatusesById: (companyId, statusesId) =>
    get(`${companyId}/statuses/status_id/${statusesId}`),
  save: (companyId, initialStatus) =>
    post(`${companyId}/statuses_subproject/`, initialStatus),
  update: (companyId, status, name) =>
    put(`${companyId}/statuses/${status}`, name),
  deleteById: (companyId, status) => remove(`${companyId}/statuses/${status}`),
  updateSequence: (companyId, subprojectId, statusId, direction) =>
    patch(
      `${companyId}/subproject/${subprojectId}/status/${statusId}`,
      direction,
    ),
  getStatusHideUsers: (statusId) =>
    get(`/user-hide-status/statusId/${statusId}`),
  postStatusHideUsers: (userIds, statusId, type) =>
    post(`/user-hide-status/`, {
      user_ids: userIds,
      status_id: statusId,
      type,
    }),
};

export const ResourcesRoutes = {
  getByCompany: (company_id) => get(`/${company_id}/resource-register`),
  postResourceRegister: (company_id, data) =>
    post(`/${company_id}/resource-register`, data),
  putResourceRegister: (company_id, data, id) =>
    put(`/${company_id}/resource-register/${id}`, data),
  postResourceInput: (company_id, data) =>
    post(`/${company_id}/resource-input`, data),
  putResourceInput: (company_id, data) =>
    put(`/${company_id}/resource-input`, data),
  deleteResourceRegisterById: (company_id, id) =>
    remove(`/${company_id}/resource-register/${id}`),
  getByTaskId: (company_id, task_id) =>
    get(`/${company_id}/resource-task/task_id/${task_id}`),
  postResourceTask: (company_id, task_id, data) =>
    post(`/${company_id}/resource-task/${task_id}`, data),
  putResourceTask: (company_id, task_id, data) =>
    put(`/${company_id}/resource-task/${task_id}`, data),
  getResourcesFieldSetByCompany: (company_id) =>
    get(`/${company_id}/resource-field-set`),
  postResourcesFieldSet: (company_id, data) =>
    post(`/${company_id}/resource-field-set`, data),
  putResourcesFieldSet: (company_id, data, id) =>
    put(`/${company_id}/resource-field-set/${id}`, data),
  deleteResourcesFieldSet: (company_id, id) =>
    remove(`/${company_id}/resource-field-set/${id}`),
};

export const DashboardUrlRoutes = {
  getUrlBySubproject: (companyId, subprojectId) =>
    get(`${companyId}/subproject/${subprojectId}/dashboard-url`),
};

export const CustomCardRoutes = {
  getCustomCardBySubproject: (companyId, subprojectId) =>
    get(`${companyId}/subproject/${subprojectId}/custom-card`),
};

export const LogsRoutes = {
  getLogsByUserLogged: (companyId) => get(`/logs/company_id/${companyId}`),
  getHistoricLogsByUserLogged: (companyId) =>
    get(`/logs/historic/company_id/${companyId}`),
  getHistoricLogsByTaskId: (taskId) => get(`/logs/historic/task/${taskId}`),
  postLastUserView: (companyId) => post(`/user-logs`, companyId),
};

export const InventoryRoutes = {
  getInventory: (companyId) => get(`${companyId}/inventory`),
  getInventoryId: (companyId, projectId) =>
    get(`${companyId}/inventory/${projectId}`),
  save: (companyId, inventory) => post(`${companyId}/inventory`, inventory),
  update: (companyId, inventory) =>
    put(`${companyId}/inventory/${inventory.id}`, inventory),
  getInventoryHistory: (companyId, inventoryId) =>
    get(`${companyId}/inventory/${inventoryId}/tasks`),
};

export const SectorRoutes = {
  getSector: (companyId, subprojectId) =>
    get(
      `${companyId}/sector?${
        subprojectId ? `sub_project_id=${subprojectId}&` : ""
      }`,
    ),
  getSectorId: (companyId, projectId) =>
    get(`${companyId}/sector/${projectId}`),
  save: (companyId, inventory) => post(`${companyId}/sector`, inventory),
  update: (companyId, inventory) =>
    put(`${companyId}/sector/${inventory.id}`, inventory),
  getNamesByUser: (userId, companyId) =>
    get(`/${companyId}/${userId}/sector/names`),
};

export const AtaRoutes = {
  save: (fields) => {
    if (fields.id) return put(`/ata/${fields.id}`, fields);

    return post("/ata", fields);
  },
  finish: (id) => post(`/ata/${id}/finish`),
  duplicate: (id) => post(`/ata/${id}/duplicate`),
  findById: (id) => get(`/ata/${id}`),
  getAtasByCompany: (companyId) => get(`/ata?company_id=${companyId}`),
  getAtasBySubproject: (subprojectId) =>
    get(`/ata?subproject_id=${subprojectId}`),
  attachUrlDigitizedAta: (id, url) =>
    post(`/ata/${id}/attach-digitized/url`, { url }),
  attachFileDigitizedAta: (id, file) =>
    post(`/ata/${id}/attach-digitized/file`, file, {
      "Content-Type": "multipart/form-data",
    }),
  sendToParticipants: (id) => post(`/ata/${id}/send`),
  report: (id) =>
    get(`/ata/${id}/report`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }),
  detailedReport: (companyId, params) => {
    let filters = "";

    Object.entries(params).forEach(([key, value]) => {
      filters += !filters.length ? `${key}=${value}` : `&${key}=${value}`;
    });

    const url = `/ata/detailed-report/${companyId}/${
      filters.length ? "?" + filters : ""
    }`;

    return get(url);
  },
  addParticipant: (ataId, userId) =>
    post(`/ata/${ataId}/participants`, { user_id: userId }),
  getParticipants: (ataId) => get(`/ata/${ataId}/participants?withUser=true`),
  deleteParticipant: (ataId, userId) =>
    remove(`/ata/${ataId}/participants/${userId}`),
  createUserAndAddAsParticipant: (ataId, fields) =>
    post(`/ata/${ataId}/participants/create-user`, fields),
  vinculateToSubprojectAndAddAsParticipant: (ataId, userId, subprojectId) =>
    post(
      `/ata/${ataId}/participants/${userId}/vinculate-user-to-subproject/${subprojectId}`,
    ),
  addSubject: (ataId, fields) => post(`/ata/${ataId}/subjects`, fields),
  updateSubject: (ataId, subjectId, fields) =>
    put(`/ata/${ataId}/subjects/${subjectId}`, fields),
  deleteSubject: (ataId, subjectId) =>
    remove(`/ata/${ataId}/subjects/${subjectId}`),
  getSubjects: (ataId) => get(`/ata/${ataId}/subjects`),
  getSubjectTasks: (ataId, subjectId) =>
    get(`/ata/${ataId}/subjects/${subjectId}/tasks`),
  addTaskToSubject: (ataId, subjectId, fields) =>
    post(`/ata/${ataId}/subjects/${subjectId}/tasks`, fields),
  updateSubjectTask: (ataId, subjectId, taskId, fields) =>
    put(`/ata/${ataId}/subjects/${subjectId}/tasks/${taskId}`, fields),
  getSubjectAttachments: (ataId, subjectId) =>
    get(`/ata/${ataId}/subjects/${subjectId}/attachments`),
  addAttachmentToSubject: (ataId, subjectId, attachment) =>
    post(`/ata/${ataId}/subjects/${subjectId}/attachments`, attachment, {
      "Content-Type": "multipart/form-data",
    }),
  deleteSubjectAttachment: (ataId, subjectId, attachmentId) =>
    remove(`/ata/${ataId}/subjects/${subjectId}/attachments/${attachmentId}`),
  subjectsCreateSector: (ataId, fields) =>
    post(`/ata/${ataId}/subjects/create-sector`, fields),
  subjectsCreateService: (ataId, fields) =>
    post(`/ata/${ataId}/subjects/create-service`, fields),
};
