import React from "react";
import "./Input.css";
import InputMask from "react-input-mask";
import CurrencyInput from "react-currency-input-field";
import Icon from "../Icon/Icon";

function Input(props) {
  const {
    errors,
    touched,
    id,
    value,
    type = "text",
    handleChange,
    className = "",
    placeholder,
    placeholderClassName,
    placeholderTitle,
    inputBackground = "transparent",
    labelBackground = "#fafafa",
    inputPlaceholder = "",
    style,
    inputType,
    name,
    mask,
    icon,
    inputIcon,
    rows,
    ...others
  } = props;

  const arrayTypes = {
    textArea: () => (
      <textarea
        {...others}
        className="w-100 border-0"
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        style={{
          backgroundColor: inputBackground ? inputBackground : "white",
          ...style,
          outline: "none",
        }}
        placeholder={inputPlaceholder}
        rows={value?.length / 38 <= 1 ? 1 : Math.ceil(value?.length / 38)}
      ></textarea>
    ),
    color: () => (
      <input
        className="w-100 border-0"
        id={id}
        name={name}
        value={value ? value : "#C4C4C4"}
        type="color"
        onChange={handleChange}
        style={{
          backgroundColor: inputBackground ? inputBackground : "white",
          ...style,
        }}
        placeholder={inputPlaceholder}
        mask={mask ? mask : ""}
        {...others}
      />
    ),

    input: () => (
      <InputMask
        className="w-100 border-0"
        id={id}
        name={name}
        value={value}
        type={type}
        onChange={handleChange}
        style={{
          backgroundColor: inputBackground ? inputBackground : "white",
          ...style,
        }}
        placeholder={inputPlaceholder}
        mask={mask ? mask : ""}
        {...others}
      />
    ),
    money: () => (
      <CurrencyInput
        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
        id={id}
        name={name}
        value={value}
        className="w-100 border-0"
        style={{
          backgroundColor: inputBackground ? inputBackground : "white",
          ...style,
        }}
        placeholder={inputPlaceholder}
        decimalsLimit={2}
        onValueChange={(value) => handleChange({ target: { name, value } })}
      />
    ),
  };

  return (
    <div className="d-flex flex-column w-100">
      <div
        className={`input-div rounded flex p-2 ${
          errors && touched ? " border-danger" : ""
        } ${className} ${others.disabled ? "disabled-input" : ""}`}
      >
        <span
          className={`span-input ${placeholderClassName || ""}`}
          title={placeholderTitle}
          style={{
            backgroundColor: labelBackground ? labelBackground : "white",
          }}
        >
          {placeholder}

          {icon ? (
            <Icon {...icon} className="span-icon">
              {icon.name}
            </Icon>
          ) : null}
        </span>

        <div className="d-flex align-items-center">
          {inputIcon ? (
            <Icon {...inputIcon} className="span-icon">
              {inputIcon.name}
            </Icon>
          ) : null}
          {inputType ? arrayTypes[inputType]() : arrayTypes["input"]()}
        </div>
      </div>
      {errors && touched ? (
        <p className="text-danger p-error m-0">{errors}</p>
      ) : null}
    </div>
  );
}

export default Input;
