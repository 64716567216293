export const longDateFormat = (date) => {
  return new Date(parseInt(date)).toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const longDateAndHourFormat = (date) => {
  return new Date(parseInt(date)).toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export const localeFormat = (date) => {
  if (!date || date.toString() === "0") return 0;
  return new Date(parseInt(date)).toLocaleString("pt-BR");
};

export const ISO8601toDate = (date) =>
  new Date(date).toISOString().slice(0, 19).replace("T", " ");

export const convertToBrazilTime = (created_at) => {
  const [year, month, day, hour, minute] = created_at.split(/[\/\s:-]/);

  const inputDateTime = new Date(year, month - 1, day, hour, minute);

  inputDateTime.setHours(inputDateTime.getHours() - 3);
  const outputDate = inputDateTime
    .toLocaleDateString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(/\//g, "/");

  return outputDate;
};
