import { Formik } from "formik";
import React from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import { Collapse } from "antd";
import "./MyProject.css";
import { myProjectDetail } from "../../assets/svg/Icons";
// import imagePlaceHolder from "../../assets/avatar-placeholder.png";
// import { Image } from "react-bootstrap";
import { RingProgress } from "@ant-design/charts";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const configPie = {
  height: 200,
  width: 200,
  autoFit: false,
  innerRadius: 0.85,
  radius: 0.98,
};

function MyTask() {
  const userStore = useSelector(userSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  const handleClick = (id, name) => {
    dispatch({ type: "SET_SUBPROJECT_ID", data: id });
    history.push("/home/projects/kanban");
  };

  return (
    <div>
      <div className="mt-2 mb-4">
        <Breadcrumb title="Projetos" routeToBack="/home" />
      </div>
      {/* <Formik initialValues={{ search: "" }} onSubmit={handleFormSubmit}>
				{({
					values,
					handleSubmit,
					handleChange,
					isSubmitting,
					errors,
					touched,
				}) => (
					<form className="w-100" onSubmit={handleSubmit}>
						<div >
							<div >
								<SearchBar
									placeholder="Nome"
									type="text"
									id="search"
									errors={errors.search}
									touched={touched.search}
									value={values.search}
									handleChange={handleChange}
									inputBackground="transparent"
									labelBackground="#fafafa"
									inputPlaceholder="Exemplo: João Silva"
								/>
							</div>
						</div>
					</form>
				)}
			</Formik> */}

      <div className="mt-4">
        <Collapse ghost defaultActiveKey={["0"]}>
          {userStore.projectInfos.map((elem, index) => {
            return (
              <Panel
                header={elem.name}
                key={index}
                collapsible
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                <div className="row" id="target-div">
                  {elem.subProjects.map((elem2) => {
                    const { tasks_waiting_approval, tasks_late, tasks_total } =
                      elem2.tasks_abstract;

                    let chartSize = 160;
                    let chartTitleSize = "12px";
                    let chartQuantitySize = "25px";

                    if (window.innerWidth < 1024) {
                      chartSize = 120;
                      chartTitleSize = "10px";
                      chartQuantitySize = "20px";
                    }

                    if (
                      !tasks_late &&
                      !tasks_waiting_approval &&
                      !tasks_total
                    ) {
                      return <></>;
                    }

                    return (
                      <div className="col-sm-12 col-md-6 p-3">
                        <div
                          className="card-project col-sm-12 p-4"
                          key={elem2.id}
                          onClick={() => handleClick(elem2.id, elem2.name)}
                        >
                          <div className="row">
                            <div className="col-sm-12 col-xl-4">
                              <p style={{ fontSize: "25px" }}>{elem2.name}</p>
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                }}
                                className="d-flex align-items-center"
                              >
                                {myProjectDetail}
                                <div className="ml-2">
                                  {tasks_total} tarefas
                                </div>
                              </div>
                            </div>
                            {/*<ul style={{ fontSize: "14px", fontWeight: "normal", listStyle: "inside", padding: "0" }}>*/}
                            {/*  <li>3 finalizadas</li>*/}
                            {/*  <li>3 finalizadas</li>*/}
                            {/*  <li>3 finalizadas</li>*/}
                            {/*</ul>*/}
                            {/*<div>*/}
                            {/*  <Image*/}
                            {/*    className="mr-2"*/}
                            {/*    width="30"*/}
                            {/*    height="30"*/}
                            {/*    src={imagePlaceHolder}*/}
                            {/*    roundedCircle*/}
                            {/*  />*/}
                            {/*</div>*/}

                            <div
                              className={`col-sm-12 col-xl-8 d-flex justify-content-around align-items-center ${
                                window.innerWidth < 1025 && "mt-3"
                              }`}
                            >
                              <RingProgress
                                {...configPie}
                                height={chartSize}
                                width={chartSize}
                                statistic={{
                                  title: {
                                    formatter: () => tasks_late.toString(),
                                    style: {
                                      fontSize: chartQuantitySize,
                                      fontWeight: "bold",
                                    },
                                  },
                                  content: {
                                    formatter: () => {
                                      return "TAREFAS\nATRASADA";
                                    },
                                    style: {
                                      fontWeight: "400",
                                      fontSize: chartTitleSize,
                                      marginTop: "7px",
                                      whiteSpace: "pre-line",
                                    },
                                  },
                                }}
                                percent={tasks_late / tasks_total}
                                color="#FF4C41"
                              />
                              <RingProgress
                                {...configPie}
                                height={chartSize}
                                width={chartSize}
                                statistic={{
                                  title: {
                                    formatter: () =>
                                      tasks_waiting_approval.toString(),
                                    style: {
                                      fontSize: chartQuantitySize,
                                      fontWeight: "bold",
                                    },
                                  },
                                  content: {
                                    formatter: () => {
                                      return "AGUARDANDO\nAPROVAÇÃO";
                                    },
                                    style: {
                                      fontWeight: "400",
                                      fontSize: chartTitleSize,
                                      marginTop: "7px",
                                      whiteSpace: "pre-line",
                                    },
                                  },
                                }}
                                percent={tasks_waiting_approval / tasks_total}
                                color="#F2994A"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
}

export default MyTask;
