import { CalendarTwoTone } from "@ant-design/icons";
import { Divider, Modal } from "antd";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import FormikInput from "~/components/Input/FormikInput";
import InputDatepicker from "~/components/InputDatepicker/InputDatepicker";
import { ResourcesRoutes } from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";
import { ISO8601toDate } from "~/util/date";

export default function ResourceInputModal(props) {
  const {
    open,
    onCancel,
    initialEditingValues = null,
    onSuccess,
    resourceInfo,
  } = props;
  const [loading, setLoading] = useState(false);
  const { setToast } = useContext(ToastContext);
  const [initialValues, setInitialValues] = useState(initialEditingValues);
  const { companyId } = useSelector(userSelector);

  const formValidation = (values) => {
    const errors = {};

    if (!values.quantity) {
      errors.quantity = "Campo obrigatório!";
    }

    return errors;
  };

  useEffect(() => {
    if (initialEditingValues) {
      setInitialValues(initialEditingValues);
    }
  }, [initialEditingValues]);

  const aditionalFields = resourceInfo?.fieldSet?.fields ?? [];

  const defaultValues = {
    quantity: "",
    description: "",
    invoice: "",
    input_date: "",
    id: resourceInfo.id,
    name: resourceInfo.name,
    additional_fields: aditionalFields?.map((item) => ({
      name: item.name,
      field_id: item.id,
      value: "",
    })),
  };

  return (
    <Modal
      title={
        initialValues ? (
          <h5
            style={{ color: "var( --blue-color)" }}
          >{`Atualizar de ${defaultValues.name}`}</h5>
        ) : (
          <h5
            style={{ color: "var( --blue-color)" }}
          >{`Entrada de ${defaultValues.name}`}</h5>
        )
      }
      open={open}
      style={{
        borderRadius: "5px",
        overflow: "hidden",
        padding: 0,
      }}
      bodyStyle={{
        borderRadius: "6px",
        padding: "20px",
      }}
      onCancel={onCancel}
      width="35%"
      destroyOnClose
      afterClose={() => setInitialValues(null)}
      footer={null}
    >
      <Formik
        initialValues={initialValues || defaultValues}
        enableReinitialize
        validate={formValidation}
        onSubmit={async (values, { setSubmitting, status }) => {
          try {
            const updatedAdditionalFields = values.additional_fields.map(
              (field) => ({
                ...field,
                value: values[field.name] || "",
              }),
            );

            const formattedValues = {
              ...values,
              resource_id: values.id,
              input_date: values.input_date
                ? ISO8601toDate(values.input_date)
                : null,
              additional_fields: updatedAdditionalFields,
            };

            updatedAdditionalFields.forEach((field) => {
              delete formattedValues[field.name];
            });

            const { data: newInput } = !initialValues
              ? await ResourcesRoutes.postResourceInput(
                  companyId,
                  formattedValues,
                )
              : await ResourcesRoutes.putResourceInput(
                  companyId,
                  formattedValues,
                  initialValues.id,
                );

            const formattedInput = {
              id: newInput.data.resource_id,
              quantity: newInput.data.quantity,
            };

            setToast({
              type: "success",
              title: "Sucesso",
              message: "Entrada de Recurso cadastrado com sucesso!",
              show: true,
              autohide: true,
            });

            onSuccess(formattedInput);
          } catch (err) {
            console.log(err);

            setToast({
              type: "error",
              title: "Erro",
              message: "Um erro inesperado ocorreu!",
              show: true,
              autohide: true,
            });
          } finally {
            setSubmitting(false);
            onCancel();
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          handleChange,
          touched,
          onSuccess,
          values,
        }) => (
          <Form>
            <div className="d-flex flex-column" style={{ gap: 24 }}>
              <FormikInput
                placeholder="Valor de entrada"
                name="quantity"
                error={errors.quantity}
                type="number"
              />
              <FormikInput
                placeholder="Descrição"
                name="description"
                error={errors.description}
                inputType="textArea"
              />
              <div
                className="d-flex justify-content-between"
                style={{ gap: 10 }}
              >
                <FormikInput
                  placeholder="Nota/Lote"
                  name="invoice"
                  error={errors.invoice}
                />
                <InputDatepicker
                  showTime={false}
                  placeholder="Data de entrada"
                  name="input_date"
                  error={errors.input_date}
                  style={{ minWidth: "150px" }}
                  suffixIcon={<CalendarTwoTone style={{ fontSize: "20px" }} />}
                />
              </div>
              {aditionalFields && aditionalFields.length > 0 && (
                <div>
                  <Divider className="m-0" />
                  <h6 className="my-4" style={{ color: "var(--blue-color)" }}>
                    Campos adicionais
                  </h6>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(300px, 1fr))",
                      gap: 20,
                    }}
                  >
                    {aditionalFields.map((field) => (
                      <FormikInput
                        key={field.name}
                        placeholder={field.name}
                        name={field.name}
                        error={errors[field.name]}
                      />
                    ))}
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-center">
                <button
                  className="btn-default w-25"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : initialValues ? (
                    "Atualizar"
                  ) : (
                    "Adicionar"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
