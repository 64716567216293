import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import SideNav from "../../components/SideNav/SideNav";
import Header from "../../components/Header/Header";
import Dashboard from "../Dashboard/Dashboard";
import "./Home.css";
// import InviteUser from "../User/InviteUser";
import Project from "../Project/Project";
import MyTask from "../MyTask/MyTask";
import MyProject from "../MyProject/MyProject";
import { FadeLoader } from "react-spinners";
import { LoginRoutes, ProjectRoutes, UserRoutes } from "../../http/routes";
import { useDispatch, useSelector } from "react-redux";
import CreateTask from "../CreateTask/CreateTask";
import Inventory from "../Inventory/Inventory";
import ProjectList from "../Projects/ProjectList";
import ProjectForm from "../Projects/ProjectForm";
import ServiceList from "../Service/ServiceList";
import ServiceForm from "../Service/ServiceForm";
import SectorList from "../Sector/SectorList";
import SectorForm from "../Sector/SectorForm";
import CreateInventory from "../Inventory/CreateInventory/CreateInventory";
import InventoryHistory from "../Inventory/History/InventoryHistory";
import UserProfile from "../UserProfile/UserProfile";
import DetailedReport from "~/pages/DetailedReport/DetailedReport";
import { userSelector } from "~/redux/Selectors";
import FormTemplates from "../FormTemplates";
import FormTemplateForm from "../FormTemplates/FormTemplateForm";
import UserForm from "../User/UserForm";
import UserList from "../User/UserList";
import AtaForm from "../Atas/AtaForm";
import AtaList from "../Atas/AtaList";
import AtaReport from "../AtaReport/AtaReport";
import Calendar from "../Calendar/Calendar";
import ListView from "../ListView/ListView";
import Notifications from "../Notifications/Notifications";
import EditStatus from "../EditStatus/EditStatus";
import ResourcesList from "../Resources/ResourcesList";

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const userStore = useSelector(userSelector);

  useEffect(() => {
    LoginRoutes.getUserInfo()
      .then(async (userInfoResponse) => {
        const { id, companies, photo, name, phone, email } =
          userInfoResponse.data.data;

        const company = userStore.companyId
          ? userStore.companyId
          : companies[0].id;

        dispatch({ type: "SET_USER_COMPANIES", data: companies });
        dispatch({ type: "SET_ID", data: id || null });
        dispatch({ type: "SET_COMPANY_ID", data: company });

        const projectsResponse = await ProjectRoutes.getProjects(company);
        const projects = projectsResponse.data.data;

        // verificar a regra para saber se sempre terá projetos na info inicial
        if (projects.length > 0 && !user.subprojectId) {
          const [subproject] = projects[0].subProjects;
          dispatch({ type: "SET_SUBPROJECT_ID", data: subproject?.id });
          dispatch({ type: "SET_SUBPROJECT_NAME", data: subproject?.name });
        }

        if (projects.length > 0 && user.subprojectId) {
          dispatch({ type: "SET_SUBPROJECT_ID", data: user.subprojectId });
          dispatch({
            type: "SET_SUBPROJECT_NAME",
            data: user.subprojectName,
          });
        }

        dispatch({ type: "SET_PROJECT_INFOS", data: projects });
        dispatch({
          type: "SET_USER_PHOTO",
          data: `data:image/png;base64,${photo}`,
        });
        dispatch({ type: "SET_USER_NAME", data: name });
        dispatch({ type: "SET_USER_ID", data: id });
        dispatch({ type: "SET_USER_PHONE", data: phone });
        dispatch({ type: "SET_USER_EMAIL", data: email });

        const permissions = await (
          await UserRoutes.getPermissions(company)
        ).data.data;
        dispatch({ type: "SET_PERMISSIONS", data: permissions });

        setIsLoading(false);
      })
      .catch((err) => {
        dispatch({ type: "SET_SUBPROJECT_ID", data: null });
        dispatch({ type: "SET_COMPANY_ID", data: null });
        dispatch({ type: "SET_AUTH_FALSE" });
        history.push("/login");
      });
  }, []);

  return (
    <div className="d-flex h-100v bg-home">
      {isLoading ? (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <FadeLoader color="#000000" loading={isLoading} size={100} />
        </div>
      ) : (
        <>
          <SideNav />
          <div className="content w-100" style={{ paddingLeft: "110px" }}>
            <Header />
            <div
              className="p-4"
              style={{ width: "90vw", paddingLeft: "200px" }}
            >
              <Switch>
                {/* <Route path="/home/user/invite" component={InviteUser} /> */}
                <Route
                  path="/home/atas/:subprojectId/:id"
                  component={AtaForm}
                />
                <Route path="/home/atas/:subprojectId" component={AtaList} />
                <Route path="/home/users/invite/:id" component={UserForm} />
                <Route path="/home/users" component={UserList} />
                <Route path="/home/user/profile" component={UserProfile} />
                <Route path="/home/projects/kanban" component={Project} />
                <Route path="/home/projects/calendar" component={Calendar} />
                <Route path="/home/projects/listview" component={ListView} />
                <Route path="/home/dashboard" component={Dashboard} />
                <Route path="/home/projects/mytasks" component={MyTask} />
                <Route path="/home/notifications" component={Notifications} />
                <Route path="/home/projects/myprojects" component={MyProject} />
                <Route path="/home/task/create" component={CreateTask} />
                <Route path="/home/inventory/:id" component={CreateInventory} />
                <Route path="/home/inventory" component={Inventory} exact />
                <Route
                  path="/home/inventory-history/:inventoryId"
                  component={InventoryHistory}
                />
                <Route path="/home/services" component={ServiceList} exact />
                <Route path="/home/services/:id" component={ServiceForm} />
                <Route path="/home/projects/kanban" component={Project} exact />
                <Route
                  path="/home/projects/status/:id"
                  component={EditStatus}
                />
                <Route path="/home/projects/mytasks" component={MyTask} exact />
                <Route path="/home/projects" component={ProjectList} exact />
                <Route path="/home/projects/:id" component={ProjectForm} />
                <Route path="/home/sectors" component={SectorList} exact />
                <Route path="/home/sectors/:id" component={SectorForm} />
                <Route path="/home/forms" component={FormTemplates} exact />
                <Route path="/home/forms/:id" component={FormTemplateForm} />
                <Route path="/home/resources" component={ResourcesList} />
                <Route
                  path="/home/reports/detailed-report"
                  component={DetailedReport}
                />
                <Route path="/home/reports/ata-report" component={AtaReport} />
                <Route path="/home">
                  <Redirect to="/home/dashboard" />
                </Route>
              </Switch>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Home;
