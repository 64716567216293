import React from "react";
import { TreeSelect } from "formik-antd";

const { TreeNode } = TreeSelect;

function FormikAntdTreeSelect(props) {
  const {
    errors,
    touched,
    id,
    value,
    placeholder,
    inputBackground,
    labelBackground,
    inputPlaceholder,
    options,
    withNull,
    disabled,
    ...rest
  } = props;

  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            key={item.value}
            value={item.value}
            title={item.name}
            selectable={false}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.value} value={item.value} title={item.name} />;
    });

  return (
    <div className="d-flex flex-column w-100">
      <div
        className={`input-div rounded flex flex-column ${
          errors && touched ? " border-danger" : ""
        }`}
      >
        <TreeSelect
          {...rest}
          className="w-100 border-0"
          id={id}
          value={value}
          bordered={false}
          style={{
            backgroundColor: inputBackground ? inputBackground : "white",
            padding: "5.5px",
          }}
          placeholder={inputPlaceholder}
          disabled={disabled}
          treeDefaultExpandAll
        >
          {withNull && <TreeNode value="" title={withNull} />}
          {options ? renderTreeNodes(options) : null}
        </TreeSelect>
        <span
          className="span-input"
          style={{
            backgroundColor: labelBackground ? labelBackground : "white",
          }}
        >
          {placeholder}
        </span>
      </div>
      {errors && touched ? (
        <p className="text-danger p-error m-0">{errors}</p>
      ) : null}
    </div>
  );
}

export default FormikAntdTreeSelect;
