import "./DrawerTask.css";
import { useEffect, useState } from "react";
import {
  EditOutlined,
  LoadingOutlined,
  FilePdfOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { Drawer, Image, Popover, Tabs } from "antd";

import CardComment from "../CardComment/CardComment";
import CardDetails from "../CardDetails/CardDetails";
import TaskStatus from "../TaskStatus/TaskStatus";
import CommentBox from "../CommentBox/CommentBox";
import RateTask from "../RateTask/RateTask";
import Button from "../Button/Button";
import { localeFormat } from "../../util/date";
import FormTab from "./components/FormTab";
import checkPermission from "~/helpers/checkPermission";
import CheckPermission from "../CheckPermission";
import urlIsImage from "~/helpers/urlIsImage";
import Video from "../Video";
import TaskHistory from "./components/TaskHistory";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";
import TaskResourceTab from "./components/TaskResourceTab";

const { TabPane } = Tabs;

function FooterButtons(props) {
  const { data, taskWorklogData, changeTaskValue, disabled } = props;

  return (
    <>
      {data.checkin_required && data.status.name !== "A FAZER" ? (
        // <TaskWorkLog data={taskWorklogData} />
        <></>
      ) : (
        ""
      )}
      {data.status.default_name === "CONCLUÍDO" && changeTaskValue ? (
        <RateTask
          stars={data.stars}
          changeTaskValue={changeTaskValue}
          taskId={data.id}
        />
      ) : (
        ""
      )}
      {changeTaskValue && (
        <TaskStatus
          disabled={disabled}
          taskId={data.id}
          status={data.status}
          statuses={data.statuses}
          changeTaskValue={changeTaskValue}
        />
      )}
    </>
  );
}

function DrawerTask(props) {
  const {
    show,
    onClose,
    data,
    changeTaskValue,
    handleEditTask,
    loading,
    afterSaveForm,
    afterAddField,
  } = props;

  const taskWorklogData = {
    time: data.worked_time,
    check_out: data.check_out,
    check_in: data.check_in,
  };

  const userStore = useSelector(userSelector);

  const [footer, setFooter] = useState(<></>);

  useEffect(() => {
    setFooter(
      <FooterButtons
        data={data}
        disabled={
          data?.status?.blockedUsers?.toEdit?.includes(userStore.userId) ||
          false
        }
        taskWorklogData={taskWorklogData}
        changeTaskValue={changeTaskValue}
      />,
    );
  }, [data]);

  if (loading)
    return (
      <div>
        <Drawer
          placement="right"
          visible={show}
          width="500"
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px",
          }}
          headerStyle={{
            backgroundColor: "#FFE300",
            height: "70px",
          }}
          destroyOnClose={true}
        >
          <LoadingOutlined style={{ fontSize: "40px" }} />
          <span className="mt-3" style={{ fontSize: "20px" }}>
            carregando...
          </span>
        </Drawer>
      </div>
    );

  const initialDetails = {
    name: data.name,
    id: data.id,
    responsible: data.responsible,
    observers: data.observers,
    description: data.description,
    localization: data.localization, //implementar
    sector: data.sector, //implementar
    service: data.service,
    inventory: data.inventory,
    media: data.media || [], //implementar
    priority: data.priority,
    due_date: localeFormat(data.due_date),
    start_date: localeFormat(data.start_date),
    worked_time: data.worked_time,
    status_name: data.status?.name || "N/A",
    attachments: data.attachments,
    fields: data.fields,
    recorrence_end: data.recorrence_end,
    recorrence_end_type: data.recorrence_end_type,
  };

  // const initialChecklists = {
  //   worked_time: data.worked_time,
  //   status_name: data.status?.name || "N/A",
  //   checklists: data.checklists,
  // };

  const initialComments = {
    comments: data.comments,
  };

  const commentInfo = {
    taskId: data.id,
    comments: data.comments,
    subProjectId: data.subproject.id,
  };

  const FooterMessage = changeTaskValue && (
    <>
      <CheckPermission slug="comentar-tarefas">
        <CommentBox
          disabled={data?.status?.blockedUsers?.toComment?.includes(
            userStore.userId,
          )}
          data={commentInfo}
          changeTaskValue={changeTaskValue}
        />
      </CheckPermission>
    </>
  );

  function callback(key) {
    if (
      key === "1" ||
      key === "2" ||
      key === "3" ||
      key === "5" ||
      key === "6"
    ) {
      return setFooter(
        <FooterButtons
          disabled={
            data?.status?.blockedUsers?.toEdit?.includes(userStore.userId) ||
            false
          }
          data={data}
          taskWorklogData={taskWorklogData}
          changeTaskValue={changeTaskValue}
        />,
      );
    }
    if (key === "4") {
      return setFooter(FooterMessage);
    }
    return setFooter();
  }

  const editTask = () => {
    handleEditTask(data.id);
    onClose();
  };

  const renderDrawerHeader = (
    <div className="drawer-header">
      <h4 title={initialDetails.name} className="mb-none drawer-title">
        {initialDetails.name}
      </h4>
      <span className="drawer-close" onClick={onClose}>
        Fechar
      </span>
    </div>
  );

  function renderFormTab() {
    if (checkPermission("preencher-formulario")) {
      return (
        <TabPane tab="Formulário" key="2">
          <FormTab
            taskId={data.id}
            subprojectId={data.subproject_id}
            afterSave={afterSaveForm}
            afterAddField={afterAddField}
          />
        </TabPane>
      );
    }

    return <></>;
  }

  const validTypeImagesIds = [8, 9, 10];

  return (
    <div>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => {
          onClose(false);
          setFooter(
            <FooterButtons
              disabled={
                data?.status?.blockedUsers?.toEdit?.includes(
                  userStore.userId,
                ) || false
              }
              data={data}
              taskWorklogData={taskWorklogData}
              changeTaskValue={changeTaskValue}
            />,
          );
        }}
        visible={show}
        width="700px"
        bodyStyle={{ padding: "0px" }}
        headerStyle={{
          backgroundColor: "#FFE300",
          height: "min-content",
          paddingBottom: "30px",
        }}
        title={renderDrawerHeader}
        footer={footer}
        destroyOnClose={true}
      >
        <div className="pl-4 pb-3 h-100">
          <Tabs
            tabBarGutter={20}
            className="drawer-tabs"
            defaultActiveKey="1"
            tabBarExtraContent={
              <div className="drawer-edit">
                {handleEditTask && (
                  <Button
                    style={{ fontWeight: "600" }}
                    onClick={editTask}
                    withIcon
                    disabled={data?.status?.blockedUsers?.toEdit?.includes(
                      userStore.userId,
                    )}
                    icon={<EditOutlined />}
                  >
                    Editar
                  </Button>
                )}
              </div>
            }
            onChange={callback}
            tabBarStyle={{
              // position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "#FFFFFF",
            }}
            style={{ height: "100%", overflowY: "auto" }}
          >
            <TabPane
              tab="Detalhes"
              key="1"
              style={{ height: "100%", maxWidth: "95%" }}
            >
              <div className="drawer-details">
                <CardDetails data={initialDetails} />
              </div>
            </TabPane>

            {renderFormTab()}

            <TabPane tab="Recursos" key="3">
              <TaskResourceTab taskId={data.id} />
            </TabPane>

            <TabPane tab="Comentários" key="4">
              <CardComment data={initialComments} />
            </TabPane>
            {(initialDetails.attachments?.length > 0 ||
              initialDetails?.fields?.length > 0) && (
              <TabPane tab="Mídia" key="5">
                <div className="images-grid">
                  {initialDetails?.attachments?.map((attachment) => {
                    if (
                      urlIsImage(attachment.title) ||
                      urlIsImage(attachment.url || "")
                    )
                      return <Image width="100%" src={attachment.url} />;
                    if (attachment.extension === "pdf")
                      return (
                        <div className="m-auto">
                          <a
                            href={attachment.url}
                            target="_blank"
                            rel="noreferrer"
                            width="min-content"
                          >
                            <FilePdfOutlined
                              style={{
                                fontSize: "5rem",
                                margin: "10px",
                                background: "#EAEDF1",
                                borderRadius: "10px",
                                padding: "1rem",
                              }}
                            />
                          </a>
                        </div>
                      );
                    return <Video src={attachment.url} />;
                  })}
                  {initialDetails?.fields?.map((field) => {
                    if (validTypeImagesIds.includes(field.type_id)) {
                      return field?.attachments.map((attachment) => {
                        if (attachment.name) {
                          if (
                            urlIsImage(attachment.name) ||
                            urlIsImage(attachment.path)
                          )
                            return <Image width="100%" src={attachment.url} />;
                          if (attachment.extension == "application/pdf")
                            return (
                              <div className="m-auto">
                                <a
                                  href={attachment.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  width="min-content"
                                >
                                  <FilePdfOutlined
                                    style={{
                                      fontSize: "5rem",
                                      margin: "10px",
                                      background: "#EAEDF1",
                                      borderRadius: "10px",
                                      padding: "1rem",
                                    }}
                                  />
                                </a>
                              </div>
                            );

                          return <Video src={attachment.url} />;
                        }
                      });
                    }
                  })}
                </div>
              </TabPane>
            )}
            <TabPane
              tab={
                <div
                  style={{
                    display: "flex",
                    paddingInline: "2px",
                  }}
                >
                  <span>Histórico</span>
                  <Popover
                    placement="bottomRight"
                    content={
                      <div>
                        <p
                          style={{
                            backgroundColor: "#4aef4750",
                            color: "#18ae16",
                            borderRadius: "10px",
                            width: "100%",
                            textAlign: "center",
                            border: "2px solid #18ae16",
                          }}
                        >
                          Criação
                        </p>
                        <p
                          style={{
                            backgroundColor: "#47b2ef50",
                            color: "#2D9CDB",
                            borderRadius: "10px",
                            width: "100%",
                            textAlign: "center",
                            border: "2px solid #2D9CDB",
                          }}
                        >
                          Atualização
                        </p>
                        <p
                          style={{
                            backgroundColor: "#f44e4e89",
                            color: "#ab0e0e",
                            borderRadius: "10px",
                            width: "100%",
                            textAlign: "center",
                            border: "2px solid #ab0e0e",
                          }}
                        >
                          Exclusão
                        </p>
                      </div>
                    }
                    title="Legenda de cores:"
                  >
                    <QuestionCircleTwoTone
                      style={{
                        marginLeft: "7px",
                        marginRight: "-2px",
                        fontSize: "18px",
                      }}
                      twoToneColor="#2D9CDB"
                    />
                  </Popover>
                </div>
              }
              key="6"
            >
              <TaskHistory taskId={data.id} />
            </TabPane>
          </Tabs>
        </div>
      </Drawer>
    </div>
  );
}

export default DrawerTask;
