import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import { Formik, Form } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import CollapsibleSubItems from "~/components/CollapsibleSubItems/CollapsibleSubItems";
import FormikInput from "~/components/Input/FormikInput";
import {
  InventoryRoutes,
  SectorRoutes,
  ServiceRoutes,
  StatusesRoutes,
  SubprojectRoutes,
  UserRoutes,
} from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";

const initialSubproject = {
  id: "",
  name: "",
  address: "",
  zipcode: "",
  city: "",
  uf: "",
};

const SubprojectForm = ({ projectId, selectedSubproject, onClose, onSave }) => {
  const { setToast } = useContext(ToastContext);
  const [subproject, setSubproject] = useState(initialSubproject);
  const [subprojectId, setSubprojectId] = useState("");
  const { companyId } = useSelector(userSelector);

  const isOpen = !!selectedSubproject;

  useEffect(() => {
    if (selectedSubproject?.id) {
      // SubprojectRoutes.findById(projectId, selectedSubproject.id).then(
      //   ({ data }) => ,
      // );
      setSubproject(selectedSubproject);
    }

    return () => {
      setSubproject(initialSubproject);
    };
  }, [projectId, selectedSubproject]);

  const onSubmit = (values) => {
    const saveOrUpdate = values.id
      ? SubprojectRoutes.update
      : async (projectId, values) => {
          const subprojectId = (await SubprojectRoutes.save(projectId, values))
            .data.data.id;

          setSubprojectId(subprojectId);

          let initialStatus = {
            statuses: [
              {
                name: "A FAZER",
                default_name: "A FAZER",
                sequence: 0,
                subprojectId: subprojectId,
              },
              {
                name: "FAZENDO",
                default_name: "FAZENDO",
                sequence: 1,
                subprojectId: subprojectId,
              },
              {
                name: "AGUARDANDO APROVAÇÃO",
                default_name: "AGUARDANDO APROVAÇÃO",
                sequence: 2,
                subprojectId: subprojectId,
              },
              {
                name: "CONCLUÍDO",
                default_name: "CONCLUÍDO",
                sequence: 3,
                subprojectId: subprojectId,
              },
            ],
          };
          await StatusesRoutes.save(companyId, initialStatus);
        };

    return saveOrUpdate(projectId, values)
      .then(() => onSave(values))
      .finally(() => {
        setToast({
          title: "Sucesso!",
          type: "success",
          message: "Subprojeto salvo com sucesso!",
        });
      });
  };

  return (
    <Modal
      width="60%"
      visible={isOpen}
      onCancel={onClose}
      title="Cadastro de subprojeto"
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <Formik enableReinitialize initialValues={subproject} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col md={12}>
                  <FormikInput name="name" placeholder="Nome" />
                </Col>
              </Row>

              {/* <Row className="mt-4">
                <Col md={8}>
                  <FormikInput name="address" placeholder="Endereço" />
                </Col>
                <Col md={4}>
                  <FormikInput name="zipcode" placeholder="CEP" />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={8}>
                  <FormikInput name="city" placeholder="Cidade" />
                </Col>
                <Col md={4}>
                  <FormikInput name="uf" placeholder="UF" />
                </Col>
              </Row> */}

              <CollapsibleSubItems
                title="Inventários"
                onSearch={() => InventoryRoutes.getInventory(companyId)}
                selections={values.inventory_id}
                handleSelections={(items) =>
                  setFieldValue("inventory_id", items)
                }
              />
              <CollapsibleSubItems
                title="Setores"
                onSearch={() => SectorRoutes.getSector(companyId)}
                selections={values.sector_id}
                handleSelections={(items) => setFieldValue("sector_id", items)}
              />
              <CollapsibleSubItems
                title="Usuários"
                onSearch={UserRoutes.getUsers}
                selections={values.user_id}
                handleSelections={(items) => setFieldValue("user_id", items)}
              />
              <CollapsibleSubItems
                title="Serviços"
                onSearch={() => ServiceRoutes.getServices(companyId)}
                selections={values.service_id}
                handleSelections={(items) => setFieldValue("service_id", items)}
              />
              <div className="mt-5 d-flex align-items-center justify-content-center flex-column">
                <button
                  type="submit"
                  className="btn-default"
                  disabled={isSubmitting}
                  style={{ width: "30%" }}
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : (
                    "Salvar"
                  )}
                </button>

                <Text
                  underline
                  className="mt-2"
                  onClick={onClose}
                  style={{ cursor: "pointer" }}
                >
                  Cancelar
                </Text>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SubprojectForm;
