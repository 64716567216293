import { Modal } from "antd";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import FormikInput from "~/components/Input/FormikInput";
import { ResourcesRoutes } from "~/http/routes";
import FormikAntdSelect from "~/pages/CreateTask/FormikAntdSelect";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";

export default function TaskAddResourceModal(props) {
  const { open, onCancel, resourcesAlreadyInUse = null, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const { setToast } = useContext(ToastContext);
  // const [initialValues, setInitialValues] = useState(initialEditingValues);
  const [resources, setResources] = useState();
  const { companyId } = useSelector(userSelector);

  const formValidation = (values) => {
    const errors = {};

    if (!values.resource) {
      errors.resource = "Campo obrigatório!";
    }

    return errors;
  };

  // useEffect(() => {
  //   if (initialEditingValues) {
  //     setInitialValues(initialEditingValues);
  //   }
  // }, [initialEditingValues]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data: resourcesData } = await ResourcesRoutes.getByCompany(
          companyId,
        );

        const resourcesDataFilteringAvailableAmountAndNotInUse =
          resourcesData?.data?.filter((item) => {
            const resourceAlreadyInUseKeys = Object.keys(
              resourcesAlreadyInUse.values,
            ).map(Number);
            return (
              item.amount > 0 && !resourceAlreadyInUseKeys.includes(item.id)
            );
          });

        setResources({
          values: resourcesDataFilteringAvailableAmountAndNotInUse?.map(
            (elem) => ({
              value: elem.id,
              name: elem.name,
            }),
          ),
          meta: resourcesDataFilteringAvailableAmountAndNotInUse?.map(
            (elem) => ({
              ...elem,
              resource_id: elem.id,
            }),
          ),
        });
      } catch (error) {
        console.error(error);
        setToast({
          type: "error",
          title: "Erro",
          message: "Um erro ocorreu durante a listagem dos recursos.",
          show: true,
          autohide: true,
        });
      }
    };

    getData();
    setLoading(false);
  }, [companyId, resourcesAlreadyInUse]);

  const defaultValues = {
    resource: "",
  };

  return (
    <Modal
      title="Adicionar recurso"
      open={open}
      style={{
        borderRadius: "5px",
        overflow: "hidden",
        padding: 0,
      }}
      bodyStyle={{
        borderRadius: "6px",
        padding: "20px",
      }}
      onCancel={onCancel}
      width="35%"
      destroyOnClose
      // afterClose={() => setInitialValues(null)}
      footer={null}
    >
      <Formik
        // initialValues={initialValues || defaultValues}
        initialValues={defaultValues}
        enableReinitialize
        validate={formValidation}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const selectedResource = resources.meta.find(
              (item) => item.id === values.resource,
            );

            const newResource = {
              values: { [selectedResource.id]: 0 },
              meta: selectedResource,
            };
            onSuccess(newResource);
            setToast({
              type: "success",
              title: "Sucesso",
              message: "Recurso cadastrado com sucesso!",
              show: true,
              autohide: true,
            });
          } catch (err) {
            console.error(err);
            setToast({
              type: "error",
              title: "Erro",
              message: "Um erro inesperado ocorreu!",
              show: true,
              autohide: true,
            });
          } finally {
            setSubmitting(false);
            onCancel();
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          handleChange,
          touched,
          onSuccess,
          values,
        }) => (
          <Form>
            <div className="d-flex flex-column" style={{ gap: 24 }}>
              <FormikAntdSelect
                allowClear
                placeholder="Recurso"
                name="resource"
                id="resource"
                errors={errors.resource}
                touched={touched.resource}
                value={values.resource}
                inputBackground="transparent"
                inputPlaceholder=""
                options={resources.values}
                maxTagCount="responsive"
              />
              <div className="d-flex justify-content-center">
                <button
                  className="btn-default w-25"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : (
                    "Adicionar"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
