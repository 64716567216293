import React from "react";
import { Select } from "formik-antd";

const { Option } = Select;

function FormikAntdSelect(props) {
  const {
    errors,
    touched,
    id,
    value,
    placeholder,
    inputBackground,
    labelBackground,
    inputPlaceholder,
    options,
    withNull,
    disabled,
    ...rest
  } = props;
  return (
    <div className="d-flex flex-column w-100">
      <div
        className={`input-div rounded flex flex-column ${
          errors && touched ? " border-danger" : ""
        }`}
      >
        <Select
          {...rest}
          className="w-100 border-0"
          id={id}
          value={value}
          bordered={false}
          style={{
            backgroundColor: inputBackground ? inputBackground : "white",
            padding: "5.5px",
          }}
          placeholder={inputPlaceholder}
          disabled={disabled}
          optionFilterProp={"label"}
          filterOption
        >
          {withNull ? <Option value="">{withNull}</Option> : ""}
          {options
            ? options.map((elem) => (
                <Option key={elem.value} value={elem.value} label={elem.name}>
                  {elem.name}
                </Option>
              ))
            : ""}
        </Select>
        <span
          className="span-input"
          style={{
            backgroundColor: labelBackground ? labelBackground : "white",
          }}
        >
          {placeholder}
        </span>
      </div>
      {errors && touched ? (
        <p className="text-danger p-error m-0">{errors}</p>
      ) : null}
    </div>
  );
}

export default FormikAntdSelect;
