import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Skeleton, Tooltip } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import FormikInput from "~/components/Input/FormikInput";
import { ResourcesRoutes } from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";
import TaskAddResourceModal from "./TaskAddResourceModal";
import "./FormTab.css";

export default function TaskResourceTab(props) {
  const { data, taskId } = props;
  const { companyId } = useSelector(userSelector);

  const [loading, setLoading] = useState(false);
  const [resourceAddModalOpen, setResourceAddModalOpen] = useState(false);

  const { setToast } = useContext(ToastContext);

  const [resourcesValues, setResourceValues] = useState({
    values: {},
    meta: [],
  });

  const formValidation = (values) => {
    const errors = {};

    Object.keys(values).forEach((key) => {
      const resourceMeta = resourcesValues.meta.find(
        (item) => item.resource_id === Number(key),
      );

      if (resourceMeta) {
        const amount = resourceMeta.amount;
        const quantity = resourceMeta.quantity ?? 0;
        const inputValue = values[key];

        if (inputValue > amount + quantity) {
          errors[key] = `O valor não pode ser maior que ${amount + quantity}!`;
        }
      }

      if (!values[key] && values[key] !== 0) {
        errors[key] = "Campo obrigatório!";
      } else if (values[key] <= 0) {
        errors[key] = "O valor não pode ser menor ou igual que 0!";
      }
    });

    return errors;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { data: resourcesData } = await ResourcesRoutes.getByTaskId(
          companyId,
          taskId,
        );
        const formattedInitialValues = resourcesData.data.reduce(
          (acc, item) => ({
            ...acc,
            [item.resource_id]: item.quantity,
          }),
          {},
        );

        setResourceValues({
          values: formattedInitialValues,
          meta: resourcesData.data,
        });
      } catch (error) {
        console.error(error);
        setToast({
          type: "error",
          title: "Erro",
          message: "Um erro ocorreu durante a listagem dos recursos.",
          show: true,
          autohide: true,
        });
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [taskId]);

  const handleAddResource = (newResource) => {
    setResourceValues((prevResources) => ({
      values: {
        ...prevResources.values,
        ...newResource.values,
      },
      meta: [...prevResources.meta, newResource.meta],
    }));
  };

  return (
    <div
      style={{
        paddingRight: "22px",
      }}
    >
      <Formik
        initialValues={resourcesValues.values}
        enableReinitialize
        validate={formValidation}
        onSubmit={async (values, { setSubmitting, status }) => {
          const isPostOperation =
            !resourcesValues?.meta || resourcesValues.meta.length === 0;

          try {
            const resources = Object.keys(values).map((resourceId) => ({
              resource_id: Number(resourceId),
              quantity: values[resourceId],
            }));

            const payload = { resources };

            if (isPostOperation) {
              await ResourcesRoutes.postResourceTask(
                companyId,
                taskId,
                payload,
              );
            } else {
              await ResourcesRoutes.putResourceTask(companyId, taskId, payload);
            }

            setToast({
              type: "success",
              title: "Sucesso",
              message: "Recurso cadastrado com sucesso!",
              show: true,
              autohide: true,
            });

            // onSuccess(formattedResource);
          } catch (err) {
            console.log(err);

            setToast({
              type: "error",
              title: "Erro",
              message: "Um erro inesperado ocorreu!",
              show: true,
              autohide: true,
            });
          } finally {
            setSubmitting(false);
            // onCancel();
          }
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          setValues,
          handleChange,
          errors,
        }) => {
          return (
            <Form
              className="w-100"
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Skeleton loading={loading} active>
                {resourcesValues?.meta?.length > 0 &&
                  Object.keys(resourcesValues.values).map((key) => {
                    const resourceMeta = resourcesValues.meta.find(
                      (item) => item.resource_id === Number(key),
                    );
                    const amount = resourceMeta?.amount;
                    const quantity = resourceMeta?.quantity;
                    return (
                      <div
                        key={key}
                        className="d-flex w-100"
                        style={{ gap: 10 }}
                      >
                        <FormikInput
                          key={key}
                          name={key}
                          disabled={
                            resourceMeta.register_deleted ? true : false
                          }
                          placeholderClassName="font-weight-bold"
                          placeholder={`${resourceMeta?.name?.toUpperCase()} -  (${
                            resourceMeta?.unit?.toUpperCase() || "N/A"
                          })`}
                          value={values[key]}
                          onChange={handleChange}
                          error={errors[key]}
                          type="number"
                          step="any"
                        />
                        <Tooltip
                          placement="left"
                          title={
                            resourceMeta.register_deleted
                              ? "Recurso excluído"
                              : `Quantidade disponível: ${amount}`
                          }
                          color={
                            values[key] > amount + quantity ||
                            resourceMeta.register_deleted
                              ? "red"
                              : "green"
                          }
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              minWidth: "5%",
                              paddingTop: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {
                              values[key] > amount + quantity ||
                              resourceMeta.register_deleted ? (
                                <CloseCircleTwoTone
                                  style={{ fontSize: "1.3rem" }}
                                  twoToneColor="red"
                                />
                              ) : (
                                <CheckCircleTwoTone
                                  style={{ fontSize: "1.3rem" }}
                                  twoToneColor="#52c41a"
                                />
                              )

                              // :  ${
                              //     !amount
                              //       ? 0
                              //       : values[key] < 1 || !values[key]
                              //       ? amount + quantity
                              //       : amount + quantity - values[key] // Exibe a quantidade restante no estoque
                              //   }
                            }
                          </span>
                        </Tooltip>
                      </div>
                    );
                  })}
              </Skeleton>

              <Button
                icon={<PlusCircleOutlined style={{ fontSize: "1.5em" }} />}
                className="d-flex align-items-center justify-content-center w-50"
                type="primary"
                onClick={() => setResourceAddModalOpen(true)}
                // disabled={!checkPermission("criar-template-formulario")}
              >
                Adicionar Recurso
              </Button>
              <button
                className="btn-default w-25"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <BeatLoader
                    color="#000000"
                    loading={isSubmitting}
                    size={10}
                  />
                ) : (
                  "Salvar"
                )}
              </button>
            </Form>
          );
        }}
      </Formik>
      <TaskAddResourceModal
        resourcesAlreadyInUse={resourcesValues}
        onSuccess={handleAddResource}
        onCancel={() => {
          setResourceAddModalOpen(false);
        }}
        open={resourceAddModalOpen}
      />
    </div>
  );
}
