import { Button, Modal } from "antd";
import { FieldArray, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import FormikInput from "~/components/Input/FormikInput";
import { ResourcesRoutes } from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";
import FormikAntdTreeSelect from "../../CreateTask/FormikAntdTreeSelect";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

export default function ResourceFieldsCreateOrUpdateModal(props) {
  const { open, onCancel, initialEditingValues = null, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const { setToast } = useContext(ToastContext);
  const [initialValues, setInitialValues] = useState(initialEditingValues);
  const { companyId, projectInfos } = useSelector(userSelector);

  const formValidation = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Campo obrigatório!";
    }

    const fieldsErrors = values.fields?.map((field) => {
      if (!field || !field.name || field.name === "") {
        return { name: "Campo obrigatório!" };
      }
      return null;
    });

    if (fieldsErrors?.some((error) => error !== null)) {
      errors.fields = fieldsErrors;
    }

    return errors;
  };

  useEffect(() => {
    if (initialEditingValues) {
      setInitialValues(initialEditingValues);
    }
  }, [initialEditingValues]);

  const defaultValues = {
    name: "",
  };

  return (
    <Modal
      title={
        initialValues ? (
          <h5
            style={{ color: "var( --blue-color)" }}
          >{`Atualizar: ${initialValues.name}`}</h5>
        ) : (
          <h5
            style={{ color: "var( --blue-color)" }}
          >{`Cadastrar Novos Campos`}</h5>
        )
      }
      open={open}
      style={{
        borderRadius: "5px",
        overflow: "hidden",
        padding: 0,
      }}
      bodyStyle={{
        borderRadius: "6px",
        padding: "20px",
      }}
      onCancel={onCancel}
      width="35%"
      destroyOnClose
      afterClose={() => setInitialValues(null)}
      footer={null}
    >
      <Formik
        initialValues={initialValues || defaultValues}
        enableReinitialize
        validate={formValidation}
        onSubmit={async (values, { setSubmitting, status }) => {
          try {
            const { data: newFields } = !initialValues
              ? await ResourcesRoutes.postResourcesFieldSet(companyId, values)
              : await ResourcesRoutes.putResourcesFieldSet(
                  companyId,
                  values,
                  initialValues.id,
                );

            const formattedResource = {
              id: newFields.data.id,
              name: newFields.data.name,
              company_id: newFields.data.company_id,
              fields: newFields.data.fields,
            };

            setToast({
              type: "success",
              title: "Sucesso",
              message: "Campos salvos com sucesso!",
              show: true,
              autohide: true,
            });

            onSuccess(formattedResource);
          } catch (err) {
            console.log(err);

            setToast({
              type: "error",
              title: "Erro",
              message: "Um erro inesperado ocorreu!",
              show: true,
              autohide: true,
            });
          } finally {
            setSubmitting(false);
            onCancel();
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          handleChange,
          touched,
          onSuccess,
          values,
        }) => (
          <Form>
            <div className="d-flex flex-column" style={{ gap: 10 }}>
              <FormikInput
                placeholder="Nome do Conjunto"
                name="name"
                error={errors.name}
              />
              <hr />

              <FieldArray
                name="fields"
                render={(arrayHelpers) => (
                  <div>
                    {values.fields &&
                      values.fields.length > 0 &&
                      values.fields.map((fields, index) => (
                        <div
                          key={index}
                          className="d-flex"
                          style={{
                            gap: 10,
                            marginBottom: "16px",
                            alignItems: "center",
                          }}
                        >
                          <FormikInput
                            placeholder={`Campo ${index + 1}`}
                            name={`fields.${index}.name`}
                            value={fields?.name || ""}
                            onChange={handleChange}
                            error={errors.fields?.[index]?.name}
                          />
                          <MinusCircleOutlined
                            style={{ fontSize: "1.5em", color: "red" }}
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </div>
                      ))}
                    <Button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // margin: "auto",
                        // marginBottom: "24px",
                      }}
                      icon={<PlusCircleOutlined />}
                      onClick={() => arrayHelpers.push({ name: "" })}
                    >
                      Adicionar Novo Campo
                    </Button>
                  </div>
                )}
              />

              <div className="d-flex justify-content-center">
                <button
                  className="btn-default w-25"
                  type="submit"
                  disabled={
                    isSubmitting || !values.fields || values.fields?.length < 1
                  }
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : initialValues ? (
                    "Atualizar"
                  ) : (
                    "Adicionar"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
