import { Form, Formik } from "formik";
import { values } from "lodash";
import { useSelector, useStore } from "react-redux";
import {
  Link,
  Route,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { BeatLoader } from "react-spinners";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import FormikInput from "~/components/Input/FormikInput";
import Select from "~/components/Select/Select";
import { StatusesRoutes, UserRoutes } from "~/http/routes";
import { userSelector } from "~/redux/Selectors";
import FormikAntdSelect from "../CreateTask/FormikAntdSelect";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "~/providers/ToastProvider";
import { UserHideTypes } from "~/util/util";

export default function EditStatus() {
  const userStore = useSelector(userSelector);
  const [statusInfo, setStatusInfo] = useState();
  const [users, setUsers] = useState([]);
  const [initialValues, setInitialValues] = useState([]);

  const { id: statusId } = useParams();
  const { setToast } = useContext(ToastContext);

  const subjectName = userStore.subprojectName;

  useEffect(() => {
    async function getInitialValues() {
      try {
        //Nome do status
        const res = await StatusesRoutes.getStatusesById(
          userStore.companyId,
          statusId,
        );
        setStatusInfo(res.data.data);

        //users par abastecer os selects
        const usersRes = await UserRoutes.getCompanyUsers(userStore.companyId);

        setUsers(
          usersRes.data.data.map((elem) => ({
            value: elem.id,
            name: elem.name,
          })),
        );

        //users salvos em banco visualization
        const hideUserDb = await StatusesRoutes.getStatusHideUsers(statusId);
        const blockVisualizationUserIdDb = hideUserDb.data?.data.user_ids
          .filter((user) => user.type === UserHideTypes.VISUALIZATION)
          .map((user) => user.user_id);
        const blockEditionUserIdDb = hideUserDb.data?.data.user_ids
          .filter((user) => user.type === UserHideTypes.EDITION)
          .map((user) => user.user_id);
        const blockCommentUserIdDb = hideUserDb.data?.data.user_ids
          .filter((user) => user.type === UserHideTypes.COMMENT)
          .map((user) => user.user_id);

        setInitialValues({
          name: res.data.data.name || "",
          visualization: blockVisualizationUserIdDb || [],
          edition: blockEditionUserIdDb || [],
          comments: blockCommentUserIdDb || [],
        });
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    }
    getInitialValues();
  }, [statusId, userStore.companyId]);

  const formValidation = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Campo obrigatório!";
    }

    //  if (!values.visualization) {
    //    errors.visualization = "Campo obrigatório!";
    //  }

    //  if (!values.edition) {
    //    errors.edition = "Campo obrigatório!";
    //  }

    return errors;
  };

  return (
    <>
      <div className="d-flex">
        <Breadcrumb
          title={subjectName}
          routeToBack="/home/projects/kanban"
          textColor="var(--blue-color)"
        />
        <span className="ml-1">/ Editando configuração de Status</span>
      </div>
      <div className="mt-5">
        <h3>
          Editar configuração do Status: {statusInfo?.name || "Carregando..."}
        </h3>
        <h5 className="mt-5 mb-4">Configurações gerais</h5>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validate={formValidation}
          onSubmit={async (values, { setSubmitting, onSuccess }) => {
            try {
              const userHideUpdates = [
                { field: "visualization", type: UserHideTypes.VISUALIZATION },
                { field: "edition", type: UserHideTypes.EDITION },
                { field: "comments", type: UserHideTypes.COMMENT },
              ];

              for (const update of userHideUpdates) {
                if (initialValues[update.field] !== values[update.field]) {
                  await StatusesRoutes.postStatusHideUsers(
                    values[update.field],
                    statusId,
                    update.type,
                  );
                }
              }

              if (statusInfo.name !== values.name) {
                const trimmedName = values.name.trim();
                await StatusesRoutes.update(userStore.companyId, statusId, {
                  name: trimmedName,
                });
              }

              setToast({
                type: "success",
                title: "Sucesso",
                message: "Configurações de Status salvas com sucesso!",
                show: true,
                autohide: true,
              });

              //   if (onSuccess) onSuccess(statusHideUser);
            } catch (err) {
              console.log(err);

              setToast({
                type: "error",
                title: "Erro",
                message: "Um erro inesperado ocorreu!",
                show: true,
                autohide: true,
              });
            }

            setSubmitting(false);
          }}
        >
          {({
            isSubmitting,
            errors,
            handleChange,
            touched,
            onSuccess,
            values,
          }) => (
            <Form>
              <div className="d-flex flex-column">
                <FormikInput
                  placeholder="Alterar nome do Status"
                  name="name"
                  error={errors.name}
                />

                <div
                  className="d-flex justify-content-between mt-4"
                  style={{ gap: "16px" }}
                >
                  <FormikAntdSelect
                    mode="tags"
                    allowClear
                    placeholder="Limitar Visualização"
                    name="visualization"
                    id="visualization"
                    errors={errors.visualization}
                    touched={touched.visualization}
                    value={values.visualization}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={users}
                    maxTagCount="responsive"
                  />
                  <FormikAntdSelect
                    mode="tags"
                    allowClear
                    placeholder="Limitar Edição"
                    name="edition"
                    id="edition"
                    errors={errors.edition}
                    touched={touched.edition}
                    value={values.edition}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={users}
                    maxTagCount="responsive"
                  />
                </div>
                <div
                  className="d-flex justify-content-between mt-4 w-50"
                  style={{ gap: "16px" }}
                >
                  <FormikAntdSelect
                    mode="tags"
                    allowClear
                    placeholder="Limitar Comentários"
                    name="comments"
                    id="comments"
                    errors={errors.comments}
                    touched={touched.comments}
                    value={values.comments}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={users}
                    maxTagCount="responsive"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <Link to="/home/projects/kanban">
                  <button
                    style={{
                      color: "var(--red-color)",
                      fontSize: "1.5em",
                      backgroundColor: "transparent",
                    }}
                    className="mt-5"
                  >
                    Cancelar
                  </button>
                </Link>
                <button
                  className="btn-default mt-5 w-25"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : (
                    "Salvar configurações"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
