import React from "react";

import checkPermission from "~/helpers/checkPermission";

function CheckPermission(props) {
  const { slug, logical = "or", alt = null, children } = props;

  if (slug && !checkPermission(slug, logical)) {
    return <>{alt}</>;
  }

  return <>{children}</>;
}

export default CheckPermission;
